<!--
 * @Description: 上传文件，附件上传至 华为OBS
 * @Author: 琢磨先生
 * @Date: 2022-05-29 21:10:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-07-26 12:02:29
-->


<template>
  <el-upload
    ref="upload"
    action
    :http-request="upload"
    multiple
    :on-remove="handlerRemove"
  >
    <el-button type="primary" size="small">选择文件</el-button>
    <!-- <el-icon class="el-icon--upload">
      <upload-filled />
    </el-icon>
    <div class="el-upload__text">
      拖拽至此 或
      <em>点击</em>上传
    </div>
    <template #tip></template> -->
  </el-upload>
</template>

<script>
// import ObsClient from "@/obs/esdk-obs-browserjs.3.22.3.min.js";
import ObsClient from "esdk-obs-browserjs/src/obs";

import md5_util from "@/utils/md5_util.js";
// import img_utils from "@/utils/img_utils";
// import common_api from "@/http/common_api";
// import axios from "axios";

export default {
  data() {
    return {
      fileList: [],
    };
  },
  emits: ["change", "remove"],
  props: ["show", "hook"],
  created() {
    var settings = this.$store.getters.getSettings;
    this.bucketName = "lt-file";
    this.obs_cdn = "https://filecdn.liangtingkeji.com";
    this.obs = new ObsClient({
      access_key_id: settings.obs_key_id,
      secret_access_key: settings.obs_access_key,
      server: settings.obs_server,
      timeout: 60 * 5,
    });
  },
  methods: {
    upload: async function (ctx) {
      console.log(ctx);
      var i = ctx.file.name.lastIndexOf(".");
      var ext = ctx.file.name.substring(i);
      ctx.file.ext = ext;

      var model = {
        height: 0,
        width: 0,
        size: ctx.file.size,
        psd_type: 1,
        name: ctx.file.name,
        ext: ext,
      };

      //获取md5值
      var md5 = await md5_util.fileMd5(ctx.file);
      console.log(md5);
      var res = await this.$http.get("/common/filename");
      if (res.code == 0) {
        //   axios
        //     .put(res.data.upload_url, ctx.file, {
        //       //上传进度
        //       onUploadProgress: (progressEvent) => {
        //         let num =
        //           ((progressEvent.loaded / progressEvent.total) * 100) | 0;
        //         ctx.onProgress({ percent: num });
        //       },
        //     })
        //     .then(() => {
        //       //上传成功
        //       ctx.onSuccess();
        //       ctx.file.preview_url = res.data.preview_url;
        //       this.$emit("change", ctx.file);
        //       if (!this.show) {
        //         this.$refs.upload.handleRemove(ctx.file);
        //       }
        //     });
        var result = await this.obs.putObject({
          Bucket: this.bucketName,
          Key: res.data + ext,
          SourceFile: ctx.file,
          ProgressCallback: (transferredAmount, totalAmount) => {
            var percent = (transferredAmount * 100.0) / totalAmount;
            ctx.onProgress({
              percent: percent,
            });
          },
        });
        if (result.CommonMsg.Status < 300) {
          var cdn_url = `${this.obs_cdn}/${res.data}${ext}`;
          ctx.file.file_url = cdn_url;
          model.file_url = cdn_url;
          this.$http.post("seller/v1/psd/file/add", model).then((res) => {
            if (res.code == 0) {
              ctx.onSuccess();
              this.fileList.push(res.data);
              this.$emit("change", this.fileList, this.hook);
            } else {
              ctx.onError();
            }
          });
        }
      } else {
        //移除文件
        this.$refs.upload.handleRemove(ctx.file);
      }
    },
    /**
     * 删除文件
     */
    handlerRemove(file) {
      this.fileList = this.fileList.filter((x) => x.file_url != file.file_url);
      this.$emit("change", this.fileList, this.hook);
    },
  },
};
</script>
 